<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        >
            <v-snackbar
                v-model="displayPasswordChanged"            
                elevation="10"
                top            
            >
                Password Changed Successfully.
                <template
                    v-slot:action="{ attrs }"
                >
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="setDisplayPasswordChanged(false)"
                    >close</v-btn>
                </template>
            </v-snackbar>
            <v-card            
                width="500"
            >  
                <v-card-title class="secondary--text pl-4 primary">
                    STUDENT INFORMATION
                </v-card-title>
                
            </v-card>
        </v-row>
        <v-row
            align="center"
            justify="center"
            v-for="section in sections"
            :key="section.label"            
        >
            <v-card
                class="pa-6 mt-5" 
                width="500"
                v-if="section.display"
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in sectionFields(section.number)"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                       
                    >                       

                        <v-combobox
                            v-if="field.type == 'select' && field.label == 'Town' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"                            
                            @blur="validateSection(field.model)"
                            chips
                            autocomplete="none"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            :ref="field.ref"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-combobox>

                        <v-combobox
                            v-if="field.type == 'select' && field.label == 'Town'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                             
                            @blur="validateSection(field.model)"
                            autocomplete="none"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"                            
                            :ref="field.ref"
                            :menu-props="{ top:false, offsetY: false}"
                            counter
                            :maxlength="field.maxlength"  
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-combobox>
                    
                        <v-autocomplete
                            v-else-if="field.type == 'select' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                        
                            @change="validateSection(field.model)"
                            chips
                            autocomplete="false"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :disabled="field.disabled"
                            :filled="field.filled"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            :ref="field.ref"
                            :menu-props="{ top:false, offsetY: false}"                            
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-autocomplete>

                        <v-autocomplete
                            v-else-if="field.type == 'select'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                        
                            @change="validateSection(field.model)"
                            chips
                            color="purple"
                            autocomplete="false"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :disabled="field.disabled"
                            :filled="field.filled"                            
                            :ref="field.ref"
                            :menu-props="{ top:false, offsetY: false}"                                                       
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-autocomplete>
                   
                        <v-row
                            v-else-if="field.type == 'switch'"
                            align="center"
                            justify="center"                            
                        >
                            <v-col cols="9">
                                {{ field.label }}
                            </v-col>

                            <v-col
                                class="d-flex"                        
                                cols="3"                    
                            >
                                <v-switch
                                    v-model="immigrationPermit"
                                    :label="setLabel(immigrationPermit)"
                                    @change="toggleImmigration"
                                    :ref="field.ref"                                    
                                >
                                    <template v-slot:append>
                                        <v-fade-transition>
                                            <v-progress-circular
                                                v-if="field.save"
                                                size="16"
                                                width="3"
                                                color="info"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveSuccess"
                                                small
                                                color="info"                                        
                                            >
                                                mdi-check-all
                                            </v-icon>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveError"
                                                small
                                                color="red"                                        
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-fade-transition>
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row> 

                        <v-text-field
                            v-else-if="field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            @keypress="numberOnly"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            prefix="1868"
                            maxlength="7"
                            autocomplete="none"
                            :ref='field.ref'
                            :rules="[() => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed']"                                                  
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                                          
                        <v-menu
                            v-else-if="field.type == 'date'"
                            ref="menuBirthDate"
                            v-model="calendarMenu[field.modelMenu]"
                            :close-on-content-click="false"                           
                            transition="scale-transistion"
                            offset-y
                            min-width="290px"                            
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="studentRecord[field.model]"
                                    :label="field.label"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    autocomplete="false"
                                    :ref='field.ref'                                    
                                    :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                                >
                                    <template v-slot:append>
                                        <v-fade-transition>
                                            <v-progress-circular
                                                v-if="field.save"
                                                size="16"
                                                width="3"
                                                color="info"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveSuccess"
                                                small
                                                color="info"                                        
                                            >
                                                mdi-check-all
                                            </v-icon>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveError"
                                                small
                                                color="red"                                        
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-fade-transition>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="studentRecord[field.model]"                                
                                @input="setDate(field.label, field.model)"
                                :max="new Date().toISOString().substr(0, 10)"
                            >                                
                            </v-date-picker>                            
                        </v-menu>

                        <v-text-field
                            v-else-if="field.required && field.label == 'Email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :ref="field.ref"
                            :disabled="field.disabled"
                            :filled="field.filled"
                            counter
                            :maxlength="field.maxlength" 
                            :rules="[
                                () => !!studentRecord[field.model] || 'This field is required.', () => regex.test(studentRecord[field.model]) || 'Invalid e-mail' ]"                           
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="field.label == 'Email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            counter
                            :maxlength="field.maxlength" 
                            :ref="field.ref" 
                            :rules="[() => regex.test(studentRecord[field.model]) || 'Invalid e-mail' ]"                           
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :counter="!field.disabled"
                            :maxlength="field.maxlength" 
                            :ref="field.ref" 
                            :disabled="field.disabled"
                            :filled="field.filled"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"                           
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="none"
                            :counter="!field.disabled"
                            :maxlength="field.maxlength" 
                            :disabled="field.disabled"
                            :filled="field.filled"
                            :ref="field.ref"       
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                    </v-col>                
                </v-row>            
            </v-card>
        </v-row>

        <v-row
            align="center"
            justify="center"
            v-if="section7"                        
        >
            <v-card
                class="pa-6 mt-5" 
                width="500"                
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in sectionFields(7)"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                                                
                        <v-menu
                            v-if="field.type == 'date'"
                            ref="menu"
                            v-model="calendarMenu[field.modelMenu]"
                            :close-on-content-click="false"                           
                            transition="scale-transistion"
                            offset-y
                            min-width="290px"                            
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="studentRecord[field.model]"
                                    :label="field.label"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    autocomplete="false"
                                    :ref='field.ref'                                    
                                >
                                    <template v-slot:append>
                                        <v-fade-transition>
                                            <v-progress-circular
                                                v-if="field.save"
                                                size="16"
                                                width="3"
                                                color="info"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveSuccess"
                                                small
                                                color="info"                                        
                                            >
                                                mdi-check-all
                                            </v-icon>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveError"
                                                small
                                                color="red"                                        
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-fade-transition>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="studentRecord[field.model]"                                
                                @change="setDate(field.label, field.model)"
                                :max="new Date().toISOString().substr(0, 10)"
                            >                                
                            </v-date-picker>                            
                        </v-menu>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>                    
                </v-row>
            </v-card>                
        </v-row>

    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'StudentData',
    components: {
        Layout,
    },
    
    data: () => ({        
        fields: [
            { label: 'section 1', break: true, number: 1, display: true},
            {
                label: 'House', 
                model: 'house_code',
                table: 'students', 
                type: 'select', 
                disabled: true,
                filled: false,
                required: false,
                ref: 'house',
                items: [],
                text: 'name',
                value: 'id',
                section: 1,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Class', 
                model: 'form_class_id',
                table: 'students', 
                type: 'text', 
                disabled: true,
                filled: true,
                required: false,
                ref: 'class',
                items: [],
                section: 1,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            { label: 'section 2', break: true, number: 2, display: true},
            {
                label: 'First Name', 
                model: 'first_name',
                table: 'students', 
                type: 'text',
                required: false,
                ref: 'first_name',
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 20,
            },
            {
                label: 'Last Name', 
                model: 'last_name',
                table: 'students', 
                type: 'text',
                required: false,
                ref: 'last_name', 
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 20,
            },
            {
                label: 'Other Name', 
                model: 'other_name',
                table: 'students', 
                type: 'text',
                required: false,
                ref: 'middle_name', 
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 20,
            },
            {
                label: 'Gender', 
                model: 'gender',
                table: 'students', 
                type: 'select',
                items: [{text: 'Female', value: 'Female'}, {text: 'Male', value: 'Male'}],
                required: false,
                ref: 'sex',
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,                
            },
            {label: 'section 3', break: true, number: 3, display: true},
            {
                label: 'Address', 
                model: 'address_home',
                table: 'student_data_personal', 
                type: 'text', 
                icon: 'mdi-home',
                required: false,
                ref: 'home_address', 
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 100,
            },
            {
                label: 'Town', 
                model: 'town',
                table: 'student_data_personal', 
                type: 'select',
                items: [],
                text: 'name',
                value: 'name',                
                placeholder: 'Type to search for town',
                icon: 'mdi-city',
                required: false,
                ref: 'address_line_2',
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 100,
            },
            {
                label: 'Regional Corporation', 
                model: 'regional_corporation',
                table: 'student_data_personal', 
                type: 'select',
                items: [],
                text: 'title',
                value: 'title',                
                placeholder: 'Type to search for regional corporation',
                icon: 'mdi-city',
                required: false,
                ref: 'regional_corporation',
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Phone Number (Mobile)', 
                model: 'phone_mobile',
                table: 'student_data_personal', 
                type: 'phone-number', 
                icon: 'mdi-cellphone',
                required: false,
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Email', 
                model: 'email',
                table: 'student_data_personal', 
                type: "email", 
                icon: 'mdi-email',
                required: false,
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 50,
            },
            {
                label: 'Blood Group', 
                model: 'blood_group',
                table: 'student_data_personal', 
                type: "select",
                items: [
                    'O-positive',
                    'O-negative',
                    'A-positive',
                    'A-negative',
                    'B-positive',
                    'B-negative',
                    'AB-positive',
                    'AB-negative'
                ],
                placeholder: 'Type to search for blood group',
                icon: 'mdi-water',
                required: false,                
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {label: 'section 4', break: true, number: 4, display: true},
            {
                label: 'Previous School', 
                model: 'previous_school', 
                table: 'student_data_personal',
                type: 'text',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 50,
            },
            {
                label: 'SEA Number', 
                model: 'sea_number',
                table: 'students', 
                type: 'text',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 15,
            },
            {
                label: 'Religion', 
                model: 'religion_id',
                table: 'student_data_personal', 
                type: 'select',
                items: [],
                text: 'grouping',
                value: 'id',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Ethnic Group', 
                model: 'ethnic_group_id',
                table: 'student_data_personal', 
                type: 'select',
                items: [],
                text: 'grouping',
                value: 'id',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {label: 'section 5', break: true, number: 5, display: true},            
            {
                label: 'Electronic Birth Pin', 
                model: 'birth_certificate_pin',
                table: 'students', 
                type: 'text', 
                icon: 'mdi-dialpad',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 15,
            },            
            {
                label: 'Date of Birth', 
                model: 'date_of_birth',
                table: 'students',
                modelMenu: 'menuBirthDate',
                type: 'date', 
                icon: 'mdi-calendar',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
            },            
            {
                label: 'Country of Birth', 
                model: 'country_of_birth',
                table: 'student_data_personal', 
                type: 'text', 
                icon: 'mdi-earth',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 25,
            },
            {
                label: 'Nationality', 
                model: 'nationality',
                table: 'student_data_personal', 
                type: 'text', 
                icon: 'mdi-flag',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 25,
            },
            // {label: 'section 6', break: true, number: 6, display: true},           
            // {
            //     label: 'Do you have an Immigration Student Permit', 
            //     model: 'immigration_permit', 
            //     type: 'switch',
            //     required: false,
            //     section: 6,
            // },
            // {label: 'section 7', break: true, number: 7, display: false},   
            // {
            //     label: 'Date of Issue', 
            //     model: 'permit_issue_date',
            //     modelMenu: 'menuIssueDate', 
            //     type: 'date',
            //     required: false,
            //     section: 7,
            //     display: false,
            // },
            // {
            //     label: 'Expiry Date', 
            //     model: 'permit_expiry_date',
            //     modelMenu: 'menuExpiryDate', 
            //     type: 'date',
            //     section: 7,
            //     required: false,
            //     display: false,
            // },            
            // {
            //     label: 'Second Language', 
            //     model: 'second_language', 
            //     type: 'text',
            //     icon: 'mdi-earth',
            //     section: 7,
            //     required: false,
            //     display: false,
            // },
        ],        
        studentRecord: {},
        dataStudent: {}, 
        dataPersonalStudent: {},
        section7: false,
        immigrationPermit: false,        
        menu: false,        
        calendarMenu: {
            menuBirthDate: false,
            menuIssueDate: false,
            menuExpiryDate: false,
        },
        date: null,
        sectionValid: false,
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            required: value => !!value || 'This field is required.'
        },
        snackbar: true,
        saving: false,
        saved: false,
        saveError: false,        
    }),

    mounted: function () {
        this.initialize();        
    },
    
    computed: {        
        ...mapGetters({            
            displayPasswordChanged: 'auth/getDisplayPasswordChanged',
            getStudentRecord: 'auth/getStudentRecord',
        }),
        sections(){
            return this.fields.filter( field => field.break == true);
        },
        regex(){
            return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
        },
    },

    watch: {
       //
    },

    methods: {
        ...mapActions({
            getStudent: 'form/getStudent',
            getStudentDataPersonal: 'form/getStudentDataPersonal',
            getTowns: 'form/getTowns',
            getReligions: 'form/getReligions',
            getEthnicGroups: 'form/getEthnicGroups',
            getRegionalCorporations: 'form/getRegionalCorporations',
            getHouses: 'form/getHouses',
            postStudentRecord: 'form/postStudentRecord',
            postStudentDataPersonal: 'form/postStudentDataPersonal',
        }),

         ...mapMutations({
            setOverlay: 'auth/setOverlay',
            setStudentRecord: 'form/setStudentRecord',
            setStudentPersonalData: 'form/setStudentDataPersonal',
            setTowns: 'form/setTowns',
            setReligions: 'form/setReligions',
            setEthnicGroups: 'form/setEthnicGroups',
            setStudentDataValidated: 'form/setStudentDataValidated',
            setValidationErrors: 'form/setValidationErrors',
            setUser: 'form/setUser',
            studentDataFields: 'form/setStudentDataFields',
            setTermsAndConditions: 'form/setTermsAndConditions',
            setDialog: 'form/setDialog',
            setDisplayPasswordChanged: 'auth/setDisplayPasswordChanged',            
        }),

        async initialize () {
            this.setOverlay(true);
            try {
                const promiseStudent = this.getStudent();
                const promiseStudentDataPersonal = this.getStudentDataPersonal();
                const promiseTowns = this.getTowns();
                const promiseReligions = this.getReligions();
                const promiseEthnicGroup = this.getEthnicGroups();
                const promiseRegionalCorporations = this.getRegionalCorporations();
                const promiseHouses = this.getHouses();
                

                const [ 
                    { data: dataStudent },
                    { data: dataStudentDataPersonal },
                    { data: dataTowns },
                    { data: dataReligions},
                    { data: dataEthnicGroups },
                    { data: dataRegionalCorporations },
                    { data: dataHouses }
                ] = await Promise.all([
                    promiseStudent,
                    promiseStudentDataPersonal,
                    promiseTowns,
                    promiseReligions,
                    promiseEthnicGroup,
                    promiseRegionalCorporations,
                    promiseHouses
                ])
                
                this.mapStudentRecord(dataStudent, dataStudentDataPersonal);
                this.mapTowns(dataTowns);
                this.mapReligions(dataReligions);
                this.mapEthnicGroups(dataEthnicGroups);
                this.mapRegionalCorporations(dataRegionalCorporations);
                this.mapHouses(dataHouses);

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setOverlay(false);
        },

        mapStudentRecord (dataStudent, dataPersonal) {
            this.studentRecord = {...dataStudent, ...dataPersonal}
            this.dataStudent = {...dataStudent};
            this.dataPersonalStudent = {...dataPersonal};
            // if(this.studentRecord.terms_and_conditions == 0) this.setTermsAndConditions(false);
            // else this.setTermsAndConditions(true);
        },

       
        async updateStore(model){
            let updateStudent = false, updateStudentDataPersonal = false;

            if(this.studentRecord.town){
                this.studentRecord.town = this.studentRecord.town.name;
            }

            Object.keys(this.dataStudent).forEach(key => {
                if(key === model){
                    updateStudent = true;
                    this.dataStudent[key] = this.studentRecord[key]
                }
            });

            if(!updateStudent){
                Object.keys(this.dataPersonalStudent).forEach(key => {
                    if(key === model){
                        updateStudentDataPersonal = true;
                        this.dataPersonalStudent[key] = this.studentRecord[key];                        
                    } 
                })
            }

            try {
                if(updateStudent){
                    this.setStudentRecord(this.dataStudent);
                    const { status } = await this.postStudentRecord();
                    console.log(status);                    
                }
                else if(updateStudentDataPersonal){
                    this.setStudentPersonalData(this.dataPersonalStudent);
                    const { statusText } = await this.postStudentDataPersonal();
                    console.log(statusText);
                }
                this.setSaveStatus (model, 'saved');
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus (model, 'error');
            }
            
        },

        async mapTowns(data){
           let index = -1;
            this.fields.forEach(field => {
                if(field.model == 'town'){
                    index = this.fields.indexOf(field);
                }
            })
                
            if(index != -1) this.fields[index].items = data;
        },

        async mapReligions(data){
            let index = -1;
            this.fields.forEach(field => {
                if(field.model == 'religion_id'){
                    index = this.fields.indexOf(field);
                }
            })
            if(index != -1) this.fields[index].items = data;
        },

        async mapEthnicGroups(data){
            let index = -1;
             this.fields.forEach(field => {
                if(field.model == 'ethnic_group_id'){
                    index = this.fields.indexOf(field);
                }
            })
            if(index != -1) this.fields[index].items = data;      
        },

        async mapRegionalCorporations (data) {
            let index = -1;
            this.fields.forEach(field => {
                if(field.model == 'regional_corporation'){
                    index = this.fields.indexOf(field);
                }
            })
            
            if(index != -1) this.fields[index].items = data;
        },

        async mapHouses (data) {
           this.fields.map(value => {
                if(value.model == 'house_code'){
                    value.items = data;
                }
            })
        },

        sectionFields(number){
            return this.fields.filter( field => field.section == number);
        },

        setLabel(label){
            return label ? 'Yes' : 'No';
        },

        toggleImmigration(){            
            if(this.immigrationPermit){
                this.studentRecord.immigration_permit = 1;
            }
            else{
                this.studentRecord.immigration_permit = 0;
            }
            //this.validateSection(field.model)();
        },

        setDate(menu, model){            
            switch(menu){
                case 'Date of Birth':
                    //this.studentRecord.date_of_birth = this.$date(new Date(this.studentRecord.date_of_birth), 'dd-MM-yyyy') 
                    this.calendarMenu.menuBirthDate = false;                    
                    break;
                case 'Expiry Date':
                    this.calendarMenu.menuExpiryDate = false;
                    break;
                case 'Date of Issue':
                    this.calendarMenu.menuIssueDate = false;
                    break;        
            }                     
            this.validateSection(model)
        },

        async validateSection(model){
            console.log('Validating...');
            await this.setSaveStatus(model);
            this.updateStore(model);
        },

        setStudentDataFields(){
            let sectionFields = this.fields.filter( field => field.break !== true);
            this.studentDataFields(sectionFields);
        },

        closeSnackbar(){
            this.setDisplayPasswordChanged(false);
        },

        numberOnly($event){            
            if(!/\d/.test($event.key)) return $event.preventDefault();
        },

        showSaveAction(){
            this.saving = true;           
            
        },

        hideSaveAction(){
            this.loading = false;
            
        },

        setSaveStatus (model, status = 'saving') {
            //console.log(index);
            //console.log(this.dataFields[index-1]);
            for(const field of this.fields) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            field.saveSuccess = false;
                            field.saveError = false;                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            field.save = false;
                            field.saveError = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            field.save = false;
                            field.saveSuccess = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }
                    
        },
              
    },
    
}
</script>